@import '../assets/styles/globals';

.LD__ModalContent {
  position: relative;
  max-width: 650px;

  > div:first-child {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;

    svg {
      fill: $rich-black-lighter;
    }
  }
}

.ReactModal__Content {
  max-width: fit-content !important;
  // max-width: 450px;
}
