@import '../assets/styles/globals';

.LD__Button.button-red {
  background-color: $rich-red;

  &:hover,
  &:active {
    background-color: $rich-red-darker;
  }
}
