.survey__form {
  background-color: rgba(27, 27, 37, 0.7);

  .LD__ModalContent {
    > :nth-child(2) {
      overflow: auto;
      max-height: calc(100vh - 40px);
    }
  }
}

.survey__intro {
  .LD__Dropdown {
    min-width: 60px;
  }

  .LD__DropdownTrigger {
    padding: 0;
    font-size: 1rem;

    :first-child {
      flex: auto;
      padding-right: 0.125rem;
    }
  }

  .LD__OptionsGroup {
    min-width: 200px;
  }
}

.survey__slider {
  .LD__Slider {
    :active {
      cursor: grabbing;
    }
  }
}

.survey__multiselect {
  .LD__Checkbox {
    align-items: flex-start;

    .LD__Icon {
      min-width: 24px;
      transform: translateY(1px);
    }
  }
}
