$rich-purple-lightest: #b9add3;
$rich-purple-lighter: #9684bd;
$rich-purple-light: #735ba7;
$rich-purple: #503291;
$rich-purple-dark: #482d83;
$rich-purple-darker: #382366;
$rich-purple-darkest: #281949;
$vibrant-magenta-lightest: #f7b1d5;
$vibrant-magenta-lighter: #f38ac0;
$vibrant-magenta-light: #ef63ab;
$vibrant-magenta: #eb3c96;
$vibrant-magenta-dark: #d43687;
$vibrant-magenta-darker: #a52a69;
$vibrant-magenta-darkest: #761e4b;
$rich-blue-lightest: #9fc3df;
$rich-blue-lighter: #6fa5cf;
$rich-blue-light: #3f87bf;
$rich-blue: #0f69af;
$rich-blue-dark: #0e5f9e;
$rich-blue-darker: #0b4a7b;
$rich-blue-darkest: #083558;
$vibrant-cyan-lightest: #abe5eb;
$vibrant-cyan-lighter: #81d8e1;
$vibrant-cyan-light: #57cbd7;
$vibrant-cyan: #2dbecd;
$vibrant-cyan-dark: #29abb9;
$vibrant-cyan-darker: #208590;
$vibrant-cyan-darkest: #175f67;
$vibrant-yellow-lightest: #ffe9ad;
$vibrant-yellow-lighter: #ffde84;
$vibrant-yellow-light: #ffd35b;
$vibrant-yellow: #ffc832;
$vibrant-yellow-dark: #f3be2f;
$vibrant-yellow-darker: #ddac28;
$vibrant-yellow-darkest: #806419;
$vibrant-green-lightest: #dbebb9;
$vibrant-green-lighter: #c9e196;
$vibrant-green-light: #b7d773;
$vibrant-green: #a5cd50;
$vibrant-green-dark: #95b948;
$vibrant-green-darker: #749038;
$vibrant-green-darkest: #536728;
$rich-red-lightest: #f5a5b9;
$rich-red-lighter: #f07896;
$rich-red-light: #eb4b73;
$rich-red: #e61e50;
$rich-red-dark: #cf1b48;
$rich-red-darker: #a11538;
$rich-red-darkest: #730f28;
$white: #ffffff;
$sensitive-grey: #f8f8fc;
$sensitive-grey-dark: #f3f3f7;
$sensitive-grey-darker: #efeff1;
$sensitive-grey-darkest: #e9e9ed;
$rich-black-lightest: #93939d;
$rich-black-lighter: #6a6a73;
$rich-black-light: #494953;
$black: #1b1b25;
$rich-green-lightest: #89cdaf;
$rich-green-lighter: #72c39f;
$rich-green-light: #5ab98f;
$rich-green: #149b5f;
$rich-green-dark: #0d6c42;
$rich-green-darker: #0c5d39;
$rich-green-darkest: #094d2f;
$sensitive-green: #b4dc96;
$sensitive-yellow: #ffdcb9;
$sensitive-blue: #96d7d2;
$sensitive-pink: #e1c3cd;
$aux-grey: #f5f5f5;
$social-facebook: #3b5998;
$social-slack: #3eb991;
$social-instagram: #ca486f;
$social-mail: #5877ff;
$social-snapchat: #fffc00;
$social-salesforce: #00a1e0;
$social-twitter: #1da1f2;
$social-skype: #4aaee6;
$social-linkedin: #0274b3;
$social-teams: #5457ae;
$social-flickr: #025fdf;
$social-xing: #005b5f;
$transparent: transparent;

$rich-blue-darkest-50: #839aab;
